import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const SolLogo = ({ name, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      iris: file(relativePath: { eq: "solutions/iris-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 210) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mdm: file(relativePath: { eq: "solutions/mdm-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 72) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sliverline: file(relativePath: { eq: "solutions/silverline-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 96) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data[name].childImageSharp.fluid}
      alt={alt}
      imgStyle={{ objectFit: "contain" }}
    />
  )
}

export default SolLogo
