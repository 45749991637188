import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const SlTbImg = ({ name, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      tb00001: file(relativePath: { eq: "trusted-by-sl/tb00001.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1104) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tb00002: file(relativePath: { eq: "trusted-by-sl/tb00002.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return data[name] ? (
    <Img
      fluid={data[name].childImageSharp.fluid}
      alt={alt || ""}
      imgStyle={{ objectFit: "contain" }}
    />
  ) : null
}

export default SlTbImg
