import React, { Component } from "react"
import { Slide, Fade } from "react-slideshow-image"

import "../scss/silverline.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SlTbImg from "../components/sl-tb-img"
import SolLogo from "../components/solution-logo"
import {
  navbarUpdate,
  setDefaultHeaderTheme,
  getSlTbContents,
  getSlFeatures,
} from "../utils/common-util"

class SilverlinePage extends Component {
  constructor(props) {
    super(props)

    this.trustedByImgRef = React.createRef()
    this.trustedBySliderProps = {
      duration: 10000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: false,
      onChange: this.onTrustedBySlideChange.bind(this),
    }
    this.trustedByFadeProp = {
      transitionDuration: 400,
      infinite: false,
      indicators: false,
      autoplay: false,
      arrows: false,
    }

    this.state = {
      currentScroll: 0,
    }

    this.onPageScroll = this.onPageScroll.bind(this)
  }

  onTrustedBySlideChange = (oldIndex, newIndex) => {
    this.trustedByImgRef.current.goTo(newIndex)
  }

  onPageScroll = e => {}

  componentDidMount() {
    setDefaultHeaderTheme(true)
    this.setState({ currentScroll: window.scrollY })
    window.addEventListener("scroll", this.onPageScroll, false)
    navbarUpdate("nav-products")
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onPageScroll, false)
  }

  render() {
    const tbContents = getSlTbContents()
    const features = getSlFeatures()

    return (
      <Layout>
        <SEO title="Silverline" />
        <div className="sl-top-content iww-full-height iww-container">
          <div className="sol-icon">
            <SolLogo name="sliverline" alt="Silverline's Logo"></SolLogo>
          </div>
          <h1 className="top-heading">
            A one stop enterprise mobility platform
          </h1>
          <div className="top-feature user-group"></div>
          <div className="top-feature trx-graph"></div>
          <div className="top-feature app-summary"></div>
          <div className="top-feature user-count"></div>
          <div className="top-feature trx-count"></div>
        </div>
        <div className="sl-feature-content iww-container">
          <div className="fea-heading">Seamless. Secure. Scalable.</div>
          <div className="fea-main-desc">
            Adapts to the heterogeneity and dynamic needs of businesses. Be it
            applications deployed on public app stores (B2C) or distribution to
            an organizations field force and management (B2B), Silverline is for
            everyone!
          </div>
          <div className="features">
            {features.map(fea => {
              return (
                <div key={fea.key} className="feature">
                  <div className="fea-icon">
                    <i className="fas fa-check-circle"></i>
                  </div>
                  <div className="fea-detail">
                    <div className="fea-title">{fea.label}</div>
                    <div className="fea-desc">{fea.desc}</div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="fea-detail-content iww-container">
          <div className="fd-heading">Spotlight on Innovation</div>
          <div className="fd-desc">
            A platform for intelligent collaboration of devices. Here’s an
            insight into some of the exclusive dashboard features.
          </div>
          <div className="fea-detail-row fdr-swipe">
            <div className="fea-detail-info">
              <div className="fea-detail-title">User & Group management</div>
              <div className="fea-detail-desc">
                Secure and organize application access by enterprise user and
                group setup.
              </div>
            </div>
            <div className="fd-gap"></div>
            <div className="fea-detail-imgs">
              <div className="fd-img fd-app-summary"></div>
              <div className="fd-img fd-user-count"></div>
            </div>
          </div>
          <div className="fea-detail-row">
            <div className="fea-detail-info">
              <div className="fea-detail-title">
                Dashboard and Transaction Insights
              </div>
              <div className="fea-detail-desc">
                Get real time performance and transactions from the
                applications, generate reports and debug issues on the fly.
              </div>
            </div>
            <div className="fd-gap"></div>
            <div className="fea-detail-imgs">
              <div className="fd-img fd-trx-graph"></div>
            </div>
          </div>
          <div className="fea-detail-row fdr-swipe">
            <div className="fea-detail-info">
              <div className="fea-detail-title">
                Remote Config and Application Management
              </div>
              <div className="fea-detail-desc">
                An intuitive mobile application management solution, Silverline
                helps to easily manage translations, drop downs and other
                application configurations remotely for the applications.
              </div>
            </div>
            <div className="fd-gap"></div>
            <div className="fea-detail-imgs">
              <div className="fd-img fd-user-group"></div>
              <div className="fd-img fd-trx-count"></div>
            </div>
          </div>
        </div>
        <div className="sl-trusted-by-content">
          <div className="trusted-by-imgs">
            <Fade {...this.trustedByFadeProp} ref={this.trustedByImgRef}>
              {tbContents.map(tb => {
                return (
                  <div key={tb.key + "-img"} className="each-fade">
                    <SlTbImg name={tb.key}></SlTbImg>
                  </div>
                )
              })}
            </Fade>
          </div>
          <div className="trusted-by-slides">
            <div className="trusted-by-title">
              <span className="left-line"></span>
              <span>Trusted By</span>
            </div>
            <Slide {...this.trustedBySliderProps}>
              {tbContents.map(tb => {
                return (
                  <div key={tb.key + "-cnt"} className="each-slide">
                    <div className="trusted-by-head">{tb.label}</div>
                    <div className="trusted-by-text">{tb.desc}</div>
                  </div>
                )
              })}
            </Slide>
          </div>
        </div>
        {/* <div className="bottom-content iww-container">
        <div className="bottom-heading">Silverline manages 4500+ Devices for various clients</div>
        <div className="bottom-desc">Discover how it can be used for your business.</div>
        <button className="button is-primary is-large">Contact Us</button>
      </div> */}
      </Layout>
    )
  }
}

export default SilverlinePage
